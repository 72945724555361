import { useEffect, useMemo, useState } from 'react'
import { pullAllWith } from 'lodash'

import { Box, Message, Text, useNotify } from '@cutover/react-ui'
import { TaskLineItem } from 'main/components/shared/task-line-item'
import { useRightPanelTypeState } from 'main/components/layout/right-panel'
import { useCanDeleteTask, useTaskListLookupState, useTaskListTask } from 'main/recoil/data-access'
import { useLanguage } from 'main/services/hooks'
import { deleteTasks } from 'main/services/queries/use-tasks'
import { FormModal } from 'main/components/shared/form'
import { RunbookTaskBulkDeleteResponse } from 'main/services/api/data-providers/runbook-types'
import { useProcessTaskBulkDeleteResponse } from 'main/recoil/data-access/updaters__TEMPORARY/task-operations'
import { StreamModel, TaskTypeModel } from 'main/data-access/models'
import { ActiveRunbookModel, ActiveRunbookVersionModel } from 'main/data-access'

export const TasksDeleteModal = ({
  open,
  taskIds = [],
  onClose,
  onSuccess
}: {
  open: boolean
  taskIds: number[]
  onClose?: () => void
  onSuccess?: () => void
}) => {
  const notify = useNotify()
  const { t } = useLanguage('tasks', { keyPrefix: 'tasksDeleteModal' })
  const handleSuccess = useProcessTaskBulkDeleteResponse()

  const [{ taskId: editPanelId }, { closeRightPanel }] = useRightPanelTypeState('task-edit')

  const runbookId = ActiveRunbookModel.get('id')
  const runbookVersionId = ActiveRunbookVersionModel.get('id')

  const tasksLookup = useTaskListLookupState()

  const [undeletableIds, setUndeletableIds] = useState<number[]>([])

  const tasks = taskIds.map(id => tasksLookup[id]).filter(task => !!task) // important! task might be deleted in another session or window
  const taskIdsToDelete = pullAllWith(
    tasks.map(({ id }) => id),
    undeletableIds
  )
  const taskIdsToDeleteIsEmpty = taskIdsToDelete.length === 0
  const sortedTaskList = useMemo(
    () => tasks.sort((a, b) => (a?.start_latest_planned || 0) - (b?.start_latest_planned || 0)),
    [tasks]
  )
  const tasksWithMultiplePredsAndSuccs = useMemo(
    () => tasks.filter(task => (task ? task.predecessor_ids.length > 1 && task?.successor_ids.length > 1 : false)),
    [tasks]
  )

  const handleSubmit = async () => {
    return await deleteTasks(runbookId, runbookVersionId, taskIdsToDelete)
  }

  return (
    <FormModal
      formElementWrapper={false}
      onClose={onClose}
      onSuccess={(response: RunbookTaskBulkDeleteResponse) => {
        handleSuccess(response)
        notify.success(t('success', { count: taskIdsToDelete.length }))
        if (editPanelId && taskIdsToDelete.includes(editPanelId)) closeRightPanel()
        onSuccess?.()
      }}
      onSubmit={handleSubmit}
      open={open}
      title={t('title', { count: taskIds.length })}
      confirmText={t('submit', { count: taskIds.length })}
      confirmIcon="delete"
      customErrors={
        tasks.length === 0
          ? [t('tasksNotAvailable', { count: taskIds.length })]
          : taskIdsToDeleteIsEmpty
          ? [t('noDeletableTasksWarning', { count: taskIds.length })]
          : []
      }
      confirmDisabled={taskIds.length === 0 || taskIdsToDeleteIsEmpty}
    >
      {tasks.length > 0 && (
        <Box gap="small" margin={{ bottom: 'small' }}>
          {undeletableIds.length > 0 && !taskIdsToDeleteIsEmpty && (
            <Message data-testid="task-delete-modal-undeletable-tasks-warning" type="warning">
              {t('undeletableTasksWarning', { count: undeletableIds.length })}
            </Message>
          )}
          <Text>{t('description', { count: taskIds.length })}</Text>
          {/* TODO: when we implement linked runbooks functionality there is a different warning https://cutover.atlassian.net/browse/CFE-1989 */}
          <Box>
            {sortedTaskList.map(
              task =>
                task && (
                  <TaskDeleteLineItemWrapper
                    onUndeletable={id => setUndeletableIds(prev => [...prev, id])}
                    key={task.id}
                    id={task.id}
                  />
                )
            )}
          </Box>
          {tasksWithMultiplePredsAndSuccs.length > 0 && (
            <Message
              type="warning"
              message={t('multiplePredecessorAndSuccessorWarning', {
                count: tasksWithMultiplePredsAndSuccs.length,
                taskIds: tasksWithMultiplePredsAndSuccs.map(t => `#${t.internal_id}`)
              })}
            />
          )}
        </Box>
      )}
    </FormModal>
  )
}

const TaskDeleteLineItemWrapper = ({ onUndeletable, id }: { id: number; onUndeletable: (id: number) => void }) => {
  const task = useTaskListTask(id)
  const stream = StreamModel.get(task.stream_id)
  const taskType = TaskTypeModel.get(task.task_type_id)
  const getCanDeleteTask = useCanDeleteTask()
  const canDeleteTask = getCanDeleteTask(id)

  useEffect(() => {
    if (!canDeleteTask) return onUndeletable(id)
  }, [canDeleteTask])

  return (
    <TaskLineItem
      task={task}
      stream={stream}
      taskType={taskType}
      errors={canDeleteTask ? undefined : ['Unable to delete']}
    />
  )
}
